import './App.css';
import 'bootstrap-4-react';
import 'react-bootstrap';
import 'react-icons/fa';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { AuthProvider } from './components/AuthContext';

import ProjectsLayout from './components/ProjectsLayout';
import GoogleMapWithMarkers from './components/GoogleMapWithMarkers';

import MasterPlanLayout from './components/MasterPlanLayout';
import BlockLayout from './components/BlockLayout';
import PlotBookLayout from './components/PlotBookLayout';
import BookingProcessLayout from './components/BookingProcessLayout';
import PaymentLayout from './components/PaymentLayout';
import InstantBooking from './components/InstantBooking';
import SectionLayout from './components/SectionLayout';
import SelectedPlotLayout from './components/SelectedPlotLayout';

function App() {
  
  return (
    <Router>
      <AuthProvider>
        <Routes>
          <Route path="/" element={<ProjectsLayout />} />
          <Route path="/location/:projectId" element={<GoogleMapWithMarkers />} />
          <Route path="/masterplan/:projectId/" element={<MasterPlanLayout />} />
          <Route path="/block/:projectId/:masterPlanId/:blockId" element={<BlockLayout />} />
          <Route path="/booking/:projectId/:plotId/:userId" element={<PlotBookLayout />} />
          <Route path="/bookingprocess/:projectId/:plotId/:userId" element={<BookingProcessLayout />} />
          <Route path="/payment/:projectId/:plotId/:userId" element={<PaymentLayout />} />
          <Route path="/selected-plot/:projectId/:blockId/:sectionId/:plotId" element={<SelectedPlotLayout />} />
          <Route path="/section/:projectId/:blockId/:sectionId" element={<SectionLayout />} />
          <Route path="/instant-booking" element={<InstantBooking />} />  
        </Routes>      
      </AuthProvider>               
    </Router>

  );
}

export default App;
