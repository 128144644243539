import React, { useContext, useState, useEffect } from 'react';
import Form from "react-bootstrap/Form";
import { BrowserRouter as Router, Link, Route, Switch, useParams } from "react-router-dom";

import { useNavigate } from "react-router-dom";

import * as apiUrl from '../apiUrl';
import * as imgUrl from '../apiUrl';

import AuthContext from './AuthContext';

import axios from 'axios';

function PaymentLayout() {

    const { isSignedIn, signIn, user } = useContext(AuthContext);

    const navigate = useNavigate();

    let { projectId, plotId, userId } = useParams();

    const [Projects, setProjects] = useState([]);

    useEffect(() => {
        const fetchData = async (projectId, plotId, userId) => {
            try {
                if (!projectId && !plotId && !userId) return;

                // Project API Calling
                const projectresponse = await fetch(apiUrl.apiUrl + `/api/bookings/booked-detail/${projectId}/${plotId}/${userId}`);
                if (!projectresponse.ok) { // Check if response is successful
                    throw new Error('Failed to fetch data');
                }
                const projectdata = await projectresponse.json();
                setProjects(projectdata);
                console.log(projectdata);

            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };

        fetchData(projectId, plotId, userId);
    }, [projectId, plotId, userId]);

    return (

        <main className='page-content'>
            {isSignedIn && (
            <div className='booking-process-div'>
                <div className='container'>
                    <div className='flex'>
                        <div className='center-box'>
                            <div className='flex'>

                                <div className="top_title_box">
                                    <h1 className="top_title"><b>Booking Summary</b></h1>
                                    <p>You are one step away from booking your dream home</p>
                                </div>

                                <div className='inner_box' style={{ height: '431px' }}>
                                    <div className='inner_border flex_box_wrp'>
                                        <div className="flex">
                                            <div className="title text-center">
                                                <h2>Booking Summary</h2>
                                            </div>

                                            <div className="details m_h_200 pt_1">
                                            {Projects && Projects.users && Projects.users.map((user) => (
                                                <div className="input_label">
                                                    Name: <span>
                                                        <b>{user.name}</b>
                                                    </span>
                                                </div>
                                            ))}
                                            {Projects && Projects.users && Projects.users.map((user) => (
                                                <div className="input_label">
                                                    Email: <span>
                                                        <b>{user.email}</b>
                                                        </span>
                                                </div>
                                            ))}   
                                            {Projects && Projects.project && Projects.project.map((projects) => ( 
                                                <div className="input_label">
                                                    Project Name: <span>
                                                        <b>{projects.project_name}</b>
                                                        </span>
                                                </div>
                                            ))}
                                                <div className="input_label">
                                                    Amount: <span>
                                                        <b>200,000.00</b>
                                                        </span>
                                                </div>
                                            </div>
                                            <Form>
                                                <Form.Control type="hidden" name="merchant_order_id" value="provident3897-18" />
                                                <Form.Control type="submit" value="Pay Now" className="razorpay-payment-button" />
                                            </Form>


                                            {/* <div className="profile-top-ba">
                                                <div className="price-saved">
                                                    <button type="submit" className="submit button pay_btn new_pay_button" id="submit">Pay Now</button>
                                                </div>
                                            </div> */}

                                        </div>


                                    </div>
                                </div>

                            </div>



                        </div>
                    </div>
                </div>
            </div>
            )} 
        </main >
    );
}

export default PaymentLayout;
