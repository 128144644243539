import React, { useState, useEffect, useCallback, useRef } from 'react';
import { BrowserRouter as Router, Route, Switch, useParams } from 'react-router-dom';
import { GoogleMap, LoadScript, Marker, useJsApiLoader, InfoWindow } from '@react-google-maps/api';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import ListItemIcon from '@mui/material/ListItemIcon';
import BusinessIcon from '@mui/icons-material/Business';
import Tour from 'reactour';
import SchoolIcon from '@mui/icons-material/School';
import InfoIcon from '@mui/icons-material/Info';
import SettingsIcon from '@mui/icons-material/Settings';
import HomeIcon from '@mui/icons-material/Home';
import ContactMailIcon from '@mui/icons-material/ContactMail';
import LocalHospitalIcon from '@mui/icons-material/LocalHospital';
import buildingIcon from '../asserts/img/building.png'; // Adjust the path as necessary

import buildingIcon1 from '../asserts/img/building-1.png';

import Header from './header';

import * as apiUrl from '../apiUrl';
import * as imgUrl from '../apiUrl';

import { useNavigate } from "react-router-dom";

const containerStyle = {
    width: '90vw',
    height: '84vh',
    marginLeft: 'auto',
    marginRight: 'auto',
    marginTop: '60px',
    border: '10px solid #fff',
    borderRadius: '50px',
};

const parentContainerStyle = {
    padding: '2% 2%',
    width: '100%',
    height: '100%',
    boxSizing: 'border-box',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    // backgroundColor: 'black',
    overflowX: 'hidden',
};

const listItemContainerStyle = {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center', // This ensures the line is vertically centered with the list item
};

const listStyle = {
    display: 'flex',
    flexDirection: 'row',
    padding: 0,
    justifyContent: 'center',
    alignItems: 'center',
    gap: '20px', // Adjust the gap to accommodate the lines
    width: '100%',
    listStyle: 'none',
    marginTop: '-20px'
};

const listStyle_vertical = {
    display: 'flex',
    flexDirection: 'row',
    padding: 0,
    justifyContent: 'center',
    alignItems: 'center',
    gap: '5px', // Adjust the gap to accommodate the lines
    width: '10%',
    listStyle: 'none',
}

const listItemStyle = {
    color: 'white',
    padding: '2px 2px', // Adjust padding as needed
    backgroundColor: '#FF7D24',
    textAlign: 'center',
    marginBottom: '20px',
    width: '120px',
};


const listItemStyleVertical = {
    color: 'white',
    padding: '0px', // Adjust padding as needed
    align: 'left',
    marginBottom: '20px',
    width: '32px',
}

const lineStyle = {
    height: '2px', // Make sure this is enough to be visible
    backgroundColor: '#FF7D24', // This should contrast with the background
    flex: 1, // Take up available space
    width: '60px',
    marginLeft: '-20px',
    marginBottom: '20px',
};

// const defaultCenter = { lat: 13.5368, lng: 77.2473 };

function GoogleMapWithMarkers() {


    const navigate = useNavigate();

    let { projectId } = useParams();

    const [markers, setMarkers] = useState([]);

    // <p>Project ID: {projectId}</p>

    const [location, setLocation] = useState(null);
    const [menuItemsVertical, setMenuItemsVertical] = useState([]);

    const [selectedLocation, setSelectedLocation] = useState(null);

    const [selectedProjectLocation, setSelectedProjectLocation] = useState(null);

    // const firstLocation = { lat: 12.90992000, lng: 79.40419000 };

    const [firstLocation, setfirstLocation] = useState([]);

    const [center, setCenter] = useState([]); // Default center

    useEffect(() => {
        const fetchData = async () => {
            try {
                if (!projectId) return;
                const response = await fetch(apiUrl.apiUrl + `/api/location/${projectId}`);
                if (!response.ok) { // Check if response is successful
                    throw new Error('Failed to fetch data');
                }
                const data = await response.json();
                setLocation(data);
                // console.log(data);    

                const formattedData = [];
                data.forEach(item => {
                    formattedData.push({
                        id: item.id,
                        text: item.name,
                        icon: <img src={`${imgUrl.imgUrl}/storage/location/${item.location_image}`} alt={item.name} className='img-fluid' />,
                        location: {
                            lat: parseFloat(item.latitude),
                            lng: parseFloat(item.longitude)
                        }
                    });
                });

                // Update menuItemsVertical state with formatted data
                setMenuItemsVertical(formattedData);

                // console.log(formattedData);

                // Set markers based on formatted data
                setMarkers(formattedData.map(item => ({
                    id: item.id,
                    lat: parseFloat(item.location.lat),
                    lng: parseFloat(item.location.lng),
                    name: item.text
                })));


                const Locationresponse = await fetch(apiUrl.apiUrl + `/api/project/location/${projectId}`);
                if (!Locationresponse.ok) { // Check if response is successful
                    throw new Error('Failed to fetch data');
                }
                // const locationdata = await Locationresponse.json();
                // console.log(locationdata);
                // setfirstLocation({ lat: locationdata.latitude, lng: locationdata.longitude });
                // setCenter({ lat: locationdata.latitude, lng: locationdata.longitude });

                const locationdata = await Locationresponse.json();
                // alert(locationdata);
                setfirstLocation({ lat: parseFloat(locationdata.latitude), lng: parseFloat(locationdata.longitude) });
                setCenter({ lat: parseFloat(locationdata.latitude), lng: parseFloat(locationdata.longitude) });
                // console.log(data);    






            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };

        fetchData();
    }, [projectId]);

    const calculateDistance = (lat1, lon1, lat2, lon2) => {

        // Earth radius in kilometers
        const R = 6371;
        const dLat = (lat2 - lat1) * (Math.PI / 180);
        const dLon = (lon2 - lon1) * (Math.PI / 180);
        const a =
            Math.sin(dLat / 2) * Math.sin(dLat / 2) +
            Math.cos(lat1 * (Math.PI / 180)) * Math.cos(lat2 * (Math.PI / 180)) *
            Math.sin(dLon / 2) * Math.sin(dLon / 2);
        const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
        const distance = R * c;
        return distance.toFixed(2); // Distance rounded to 2 decimal places
    };


    const getLocationName = async (id) => {
        try {
            // Make a GET request to your backend API endpoint
            const response = await fetch(apiUrl.apiUrl + `/api/location/lat-lng/${id}`);

            // Check if the response is successful (status code 200)
            if (!response.ok) {
                throw new Error('Failed to fetch location name');
            }

            // Parse the JSON response
            const data = await response.json();

            // Extract and return the location name from the response data
            return data.name;

        } catch (error) {
            console.error('Error fetching location name:', error);
            // Handle errors gracefully, e.g., display an error message
            return null;
        }
    };

    const getProjectName = async (id) => {
        try {
            // Make a GET request to your backend API endpoint
            const response = await fetch(apiUrl.apiUrl + `/api/project/${id}`);

            // Check if the response is successful (status code 200)
            if (!response.ok) {
                throw new Error('Failed to fetch location name');
            }

            // Parse the JSON response
            const data = await response.json();

            // Extract and return the location name from the response data
            return data.project_name;

        } catch (error) {
            console.error('Error fetching location name:', error);
            // Handle errors gracefully, e.g., display an error message
            return null;
        }
    };


    const handleMarkerHover = async (location, id) => {
        try {

            const locationName = await getLocationName(id); 
            setSelectedLocation({ ...location, name: locationName });
            moveToLocation(location);
            console.log('Selected location:', { ...location, name: locationName });
        } catch (error) {
            console.error('Error handling marker hover:', error);            
        }
    };

    const handleProjectLocationHover = async (location, id) => {
        try {

            // console.log(id);
            const locationName = await getProjectName(id); 
            setSelectedProjectLocation({ ...location, name: locationName });
            moveToLocation(location);
            console.log('Selected location:', { ...location, name: locationName });
        } catch (error) {
            console.error('Error handling marker hover:', error);            
        }
    };


    const handleMarkerClose = () => {
        setSelectedLocation(null);
        setSelectedProjectLocation(null);
    };







    // const menuItemsVertical = [
    //     {
    //         text: 'Info 1', icon: <BusinessIcon />, location: {
    //             lat: -4.769162
    //             , lng: 11.866362
    //         }
    //     },
    //     {
    //         text: 'Info 2', icon: <SettingsIcon />, location: {
    //             lat: 14.7810,
    //             lng: 75.9870
    //         }
    //     },
    //     { text: 'Info 3', icon: <HomeIcon />, location: { lat: 13.5094, lng: 77.0409 } },
    //     { text: 'Info 4', icon: <ContactMailIcon />, location: { lat: 13.5094, lng: 77.0409 } },
    //     { text: 'Info 5', icon: <SchoolIcon />, location: { lat: 13.5094, lng: 77.0409 } },
    //     { text: 'Info 6', icon: <InfoIcon />, location: { lat: 13.5094, lng: 77.0409 } },
    //     { text: 'Info 7', icon: <LocalHospitalIcon />, location: { lat: 13.5094, lng: 77.0409 } },
    //     { text: 'Info 8', icon: <SchoolIcon />, location: { lat: 13.5094, lng: 77.0409 } },
    //     { text: 'Info 9', icon: <SchoolIcon />, location: { lat: 13.5094, lng: 77.0409 } },
    //     { text: 'Info 10', icon: <SchoolIcon />, location: { lat: 13.5094, lng: 77.0409 } },
    //     { text: 'Info 11', icon: <SchoolIcon />, location: { lat: 13.5094, lng: 77.0409 } },

    // ];


    const [activeItem, setActiveItem] = useState(0);
    const [currentStep, setCurrentStep] = useState(0);

    const center1 = {
        lat: 13.5368,
        lng: 77.2473
    };

    // Assuming you have a list of hospitals
    const hospitals = [
        { id: 1, name: "columbia asia hospitals", location: { lat: 13.5094, lng: 77.0409 } },
        // Add more hospitals as needed
    ];

    // Assuming this is the hospital's location
    const hospitalLocation = {
        lat: 14.7810,
        lng: 75.9870
    };






    // Function to generate style based on item's active state
    const getListItemStyle = (index) => ({
        color: index === activeItem ? 'white' : '#333', // Change text color based on active state
        padding: '1px 1px',
        backgroundColor: index === activeItem ? '#FF7D24' : '#f0f0f0', // Active item gets a different background
        textAlign: 'center',
        marginBottom: '20px',
        width: '120px',
        cursor: 'pointer', // Change cursor to indicate clickable items
    });


    // Adjusted styles for the main container to use column layout
    const mainContainerStyle = {
        ...parentContainerStyle,
        flexDirection: 'column',
    };

    // Style for the secondary container that holds the vertical list and the map side by side
    const secondaryContainerStyle = {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'start', // Adjust alignment as needed
        width: '100%',
    };

    // Style adjustments for the vertical list to ensure it displays correctly
    const verticalListStyle = {
        ...listStyle_vertical,
        flexDirection: 'column', // Change list to vertical orientation
        height: '100%', // Adjust height as needed
        justifyContent: 'flex-start', // Adjust alignment as needed
        width: '5px',
        marginTop: '120px',

    };


    const buttonStyle = {
        backgroundColor: '#FF7D24', // Button background color
        color: 'white', // Text color
        border: 'none', // Remove default border
        padding: '10px 20px', // Padding around the text
        cursor: 'pointer', // Cursor to pointer to indicate it's clickable
        borderRadius: '5px', // Optional: adds rounded corners to the button
        margin: '0 5px', // Optional: adds margin for spacing between buttons
    };

    const [isTourOpen, setIsTourOpen] = useState(false);
    const steps = [
        {
            selector: '.first-step',
            content: 'This is the first step of our tour!',
        },
        {
            selector: '.second-step',
            content: 'Here is the second step.',
        },
    ];

    function handleItemClick(index) {
        if (index === 0) {
            setIsTourOpen(true);
        }
        // Add more conditions for other indexes/actions as needed
    }

    const closeTour = () => {
        setIsTourOpen(false);
    };

    // Function to go to the next step
    const goToNext = () => setCurrentStep(currentStep + 1);

    // Function to go to the previous step
    const goToPrev = () => setCurrentStep(currentStep - 1);

    const mapRef = useRef(null);


    const [loadError, setLoadError] = useState(false);
    const [isMapsLoaded, setIsMapsLoaded] = useState(false);
    const [animation, setAnimation] = useState(null);
    const [customIcon, setCustomIcon] = useState(null);

    // Function to toggle animation
    // const toggleBounce = useCallback(() => {
    //     setAnimation(prev => prev === null ? window.google.maps.Animation.BOUNCE : null);
    // }, []);


    const toggleBounce = useCallback(() => {
        setAnimation(prev => prev === null ? window.google.maps.Animation.BOUNCE : null);
    }, [setAnimation]);

    
    const [map, setMap] = useState(null);

    




    //     const moveToLocation = (locationx) => {
    // console.log(locationx);
    //   console.log(mapRef.current);        
    //         if (mapRef.current) {
    //             mapRef.current.panTo(locationx);
    //             mapRef.current.setZoom(15);
    //         }
    //     };

    //     const handleMapLoad = (mapInstance) => {



    //         setMap(mapInstance); // Save the GoogleMap instance
    //         mapRef.current = mapInstance;
    //         setIsMapsLoaded(true); // Set the loaded flag to true


    //     };



    // Function adjusted for clarity
    const moveToLocation = (location) => {
        // console.log(location);

        // console.log('Attempting to move to location:', location);
        if (mapRef.current) {
            // console.log('Map instance available:', mapRef.current);
            setCenter(location.lat, location.lng); // Update the map's center
            mapRef.current.panTo(location);
            setMarkers([{ id: Date.now(), ...location, name: location.name }]); // Set a new marker

            mapRef.current.setZoom(15);
        } else {
            console.log('Map instance not available yet.');
        }
    };

    // Simplified handleMapLoad for clarity
    const handleMapLoad = (mapInstance) => {
        mapRef.current = mapInstance;
        setIsMapsLoaded(true); // Indicating the map has loaded
        // console.log('Map loaded:', mapInstance);
    };


    useEffect(() => {

        if (isMapsLoaded) {

            if (mapRef.current) {                
                console.log("Map instance:", mapRef.current);
            }           
            toggleBounce();          
            setCustomIcon({
                url: buildingIcon1,
                scaledSize: new window.google.maps.Size(50, 50),
            });           
            const intervalId = setInterval(() => {
                toggleBounce();
            }, 100);

            return () => clearInterval(intervalId);
        }
    }, [isMapsLoaded, toggleBounce]);

   
    





    return (
        <div style={mainContainerStyle}>
            {/* <List style={listStyle}>
                {menuItems.map((item, index) => (
                    <div key={item} style={listItemContainerStyle}>

                        {index !== 0 && <div style={lineStyle} />}
                        {index === 0 && <div className="first-step">First Step Content</div>}

                        <ListItem button component="a" href={`${item.toLowerCase().replace(/\s/g, "")}`} style={getListItemStyle(index)}
                            onClick={() => {
                                handleItemClick(index)
                                setActiveItem(index)
                            }
                            }>
                            <ListItemText primary={item} />
                        </ListItem>
                    </div>
                ))}
            </List> */}

            <Header />
            <Tour
                steps={steps}
                isOpen={isTourOpen}
                onRequestClose={closeTour}
                getCurrentStep={setCurrentStep}
                nextButton={
                    currentStep === 0 ? (
                        <button onClick={goToNext} style={buttonStyle}>Next</button>
                    ) : undefined
                }
                prevButton={
                    currentStep === 1 ? (
                        <button onClick={goToPrev} style={buttonStyle}>Prev</button>
                    ) : undefined
                }
                lastStepNextButton={
                    currentStep === 1 ? (
                        <button onClick={closeTour} style={buttonStyle}>Done</button>
                    ) : undefined
                }
                showButtons={true}
                disableInteraction // Optional, to prevent interacting with the highlighted elements
                accentColor="#FF7D24"
            />








            {/* Secondary Container for Vertical List and Google Map */}
            <div className="master-container-div" style={secondaryContainerStyle}>
                <div className='sidebar-left-title'>
                    <h4>Location</h4>
                </div>
                {/* Left Vertical List */}


                <List style={verticalListStyle}>

                    <ListItem button style={listItemStyleVertical} >
                        <ListItemIcon onMouseEnter={() => handleProjectLocationHover(firstLocation, projectId)} style={{ color: 'white' }} className="customIconSize">
                            <img src={buildingIcon} onClick={() => navigate(`/masterplan/${projectId}`)} className='img-fluid' />
                        </ListItemIcon>
                    </ListItem>


                    {menuItemsVertical.map((item, index) => (
                        <ListItem button key={index} id={item.id} style={listItemStyleVertical}>
                            <ListItemIcon onMouseEnter={() => handleMarkerHover(item.location, item.id)} style={{ color: 'white' }} className="customIconSize">
                                {item.icon}
                            </ListItemIcon>
                        </ListItem>
                    ))}
                    {/* {<div className="second-step">2nd Step</div>} */}
                </List>



                {/* Google Map */}
                <LoadScript googleMapsApiKey="AIzaSyBUk7YVoh3uQF3y0LpcIJZaZUW5s_HJ75w" onLoad={() => console.log('Scripts loaded successfully')}
                    onError={(e) => setLoadError(true)}>

                    {!loadError && (                        

                        <GoogleMap
                            mapContainerStyle={containerStyle}
                            center={center}
                            zoom={15}
                            onLoad={handleMapLoad}
                        >

                            <Marker
                            position={center}
                            icon= {customIcon}
                            onClick={() => navigate(`/masterplan/${projectId}`)}
                            onMouseOver={() => handleProjectLocationHover(firstLocation, projectId)}
                            onMouseOut={handleMarkerClose}
                            />

                            {markers.map((marker, index) => (
                                <Marker
                                    key={index}
                                    position={{ lat: marker.lat, lng: marker.lng }}
                                    onMouseOver={() => handleMarkerHover({ lat: marker.lat, lng: marker.lng }, marker.id)}
                                    onMouseOut={handleMarkerClose}
                                />
                            ))}

                            {selectedLocation && (
                                <InfoWindow
                                    position={{ lat: selectedLocation.lat, lng: selectedLocation.lng }}
                                    onCloseClick={handleMarkerClose}
                                >
                                    <div>
                                        <h3>{selectedLocation.name}</h3>
                                        <p>{calculateDistance(firstLocation.lat, firstLocation.lng, selectedLocation.lat, selectedLocation.lng)} km</p>
                                    </div>
                                </InfoWindow>
                            )}

                            {selectedProjectLocation && (
                                <InfoWindow
                                    position={{ lat: selectedProjectLocation.lat, lng: selectedProjectLocation.lng }}
                                    onCloseClick={handleMarkerClose}
                                >
                                    <div>
                                        <h3>{selectedProjectLocation.name}</h3>
                                        <p>{selectedProjectLocation.name}</p>
                                    </div>
                                </InfoWindow>
                            )}
                        </GoogleMap>

                    )}


                </LoadScript>
            </div>
        </div>
    );
}

export default React.memo(GoogleMapWithMarkers);

