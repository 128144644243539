import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Link } from "react-router-dom";
import Button from 'react-bootstrap/Button';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMapMarkerAlt } from '@fortawesome/free-solid-svg-icons';

import { useNavigate } from "react-router-dom";

// Swiper
import { Swiper, SwiperSlide } from 'swiper/react';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';

// import required modules
import { Navigation, Pagination, EffectFade, Autoplay } from 'swiper/modules';

import * as apiUrl from '../apiUrl';
import * as imgUrl from '../apiUrl';

const ProjectsLayout = () => {

    const navigate = useNavigate();

    const [projects, setProjects] = useState([]);    

    useEffect(() => {

        fetch(apiUrl.apiUrl + '/api/all-projects')
            .then(response => response.json())
            .then(data => {
                // console.log(data); // Log the response data to the console
                setProjects(data);
            })
            .catch(error => console.error('Error fetching data:', error));
    }, []);

    return (

        <main className='page-content'>
            <div className='flex-wrap common-top-spacing'>
                <div className='amenities-div'>
                    <div className='sidebar-left-title'>
                        <h4>Projects</h4>
                    </div>
                </div>

                <div className='block-img-div'>
                    <div className='block-img project-swiper'>
                        <Swiper className="projects-swiper"
                            spaceBetween={30}
                            slidesPerView={1}
                            grabCursor={true}
                            preventClicks={true}
                            draggable={true}
                            speed={500}
                            modules={[Navigation, Pagination, EffectFade, Autoplay]}
                            navigation={true}
                            pagination={true}
                            autoplay={true}                                                       
                            scrollbar={{ draggable: true }}
                        >
                            {projects.map(project => (
                            <SwiperSlide key={project.id}>                           
                                <div className='project-img'>
                                    <img src={imgUrl.imgUrl + `/storage/projects/${project.project_image_file}`} alt={project.project_name} />
                                </div>
                                <div className='project-content'>
                                    <img src={imgUrl.imgUrl + `/storage/projects/${project.logo_file}`} className='img-fluid' />
                                    <h2><FontAwesomeIcon icon={faMapMarkerAlt} />{project.project_name}</h2>
                                    <Button className='proceed-button' id={project.id} onClick={() => navigate(`/location/${project.id}`)}>Choose Your Units</Button>
                                </div>                                
                            </SwiperSlide>
                             ))}
                        </Swiper>
                    </div>
                </div>
            </div>
        </main>        
        );
}

export default ProjectsLayout;
