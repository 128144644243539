import Header from './header';
import { FaArrowLeft } from "react-icons/fa";
import AccountBalanceWalletIcon from '@mui/icons-material/AccountBalanceWallet';
import React, { useContext, useState, useEffect } from 'react';
import Form from "react-bootstrap/Form";
import Modal from 'react-bootstrap/Modal';
import { FaRegUserCircle } from "react-icons/fa";
import { FaHome } from "react-icons/fa";
import { BrowserRouter as Router, Link, Route, Switch, useParams } from "react-router-dom";

import { useNavigate } from "react-router-dom";

import UserDetailModal from './UserDetailModal';

import AuthContext from './AuthContext';

import * as apiUrl from '../apiUrl';
import * as imgUrl from '../apiUrl';

import EmiCalculatorModal from '../modals/EmiCalculatorModal';

import axios from 'axios';
import { Button } from 'react-bootstrap';



function PlotBookLayout() {

  const { isSignedIn, signIn, user } = useContext(AuthContext);

  const navigate = useNavigate();

  let { projectId, blockId, sectionId, plotId, userId } = useParams();

  const [Projects, setProjects] = useState([]);

  const [showUserDetailModal, setShowUserDetailModal] = useState(false);

  const handleShowUserDetailModal = () => {
    setShowUserDetailModal(true);
  };

  const handleCloseUserDetailModal = () => {
    setShowUserDetailModal(false);
  };


  const [userData, setUserData] = useState({
    name: '',
    phone: '',
    plot_id: '',
    plot_number: '',
    plot_type: '',
    section: '',
    block: '',
    direction: '',
    area: '',
    price: '',
    block_id: '',
    user_id: '',
    project_id: '',
  });

  const handleBookNow = async (e) => {
    e.preventDefault();

    try {
      const response = await axios.post(apiUrl.apiUrl + '/api/bookings/store', userData);
      console.log('Booking successful:', response.data);

      navigate(`/bookingprocess/${projectId}/${plotId}/2`);

    } catch (error) {
      if (error.response && error.response.status === 422) {
        console.log('Validation errors:', error.response.data.errors);
      } else if (error.response && error.response.status === 400) {
        alert('The selected plot is already booked');
      }
      else {
        console.error('Error booking plot:', error);
      }
    }
  };

  const handleWishList = async (e) => {
    e.preventDefault();

    try {
      const response = await axios.post(apiUrl.apiUrl + '/api/wishlist/store', userData);
      console.log('Wishlist Added Successfully:', response.data);

      window.location.reload();

    } catch (error) {
      if (error.response && error.response.status === 422) {
        console.log('Validation errors:', error.response.data.errors);
      } else if (error.response && error.response.status === 400) {
        alert('The selected plot is already in your wishlist');
      }
      else {
        console.error('Error booking plot:', error);
      }
    }
  };



  const [plotDetail, setPlotDetail] = useState(null);
  const [userDetail, setUserDetail] = useState(null);

  const [EmiCalculatorShow, setEmiCalculatorShow] = useState(false);
  const EmiCalculatorhandleClose = () => setEmiCalculatorShow(false);
  const EmiCalculatorhandleShow = () => setEmiCalculatorShow(true);


  useEffect(() => {
    if (plotId && userId) {

      // Fetch plot details
      fetch(apiUrl.apiUrl + `/api/plot-details/${plotId}`)
        .then(response => {
          if (!response.ok) {
            throw new Error('Network response was not ok');
          }
          return response.json();
        })
        .then(data => {
          setPlotDetail(data);
          setUserData(prevUserData => ({
            ...prevUserData,
            plot_id: String(plotId),
            plot_number: data.plot_number,
            plot_type: data.plot_type,
            // section: data.section,
            block: data.blocks.block_name,
            direction: data.direction,
            area: data.plot_size,
            price: data.offer_price,
            block_id: data.blocks.id,
            project_id: data.project_id,
          }));
        })
        .catch(error => {
          console.error('There was a problem with fetching plot details:', error);
        });

      // Fetch user details     
      fetch(apiUrl.apiUrl + `/api/users/${userId}`)
        .then(response => {
          if (!response.ok) {
            throw new Error('Network response was not ok');
          }
          return response.json();
        })
        .then(data => {
          setUserDetail(data);
          setUserData(prevUserData => ({
            ...prevUserData,
            name: data.user.name,
            phone: data.user.phone,
            user_id: String(userId),
          }));
        })
        .catch(error => {
          console.error('There was a problem with fetching user details:', error);
        });
    }
  }, [plotId, userId]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        if (!projectId) return;

        // Project API Calling
        const projectresponse = await fetch(apiUrl.apiUrl + `/api/project/${projectId}`);
        if (!projectresponse.ok) { // Check if response is successful
          throw new Error('Failed to fetch data');
        }
        const projectdata = await projectresponse.json();
        setProjects(projectdata);
        // console.log(projectdata);        

      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchData();
  }, [projectId]);






  if (!plotDetail || !userDetail) {
    return null;
  }

  return (
    <main className='page-content'>
      <Header />

      {isSignedIn && (

        <div className='flex-wrap common-top-spacing'>
          <div className='amenities-div'>
            <div className='sidebar-left-title'>
              <h4>Booking</h4>
            </div>
          </div>

          <div className='block-div block-mobile-div'>
            <div className='user-name'>
              <p>{user.name} </p>
            </div>
            {/* <div className='user-icon'>
              <FaRegUserCircle />
            </div> */}
             <div className='user-icon'
              onClick={handleShowUserDetailModal}
            >
              <FaRegUserCircle />
            </div>
            <UserDetailModal
              show={showUserDetailModal}
              onClose={handleCloseUserDetailModal}
            />
          </div>

          <div className='block-img-div'>
            <div className='block-img'>
              <div className='popover-box popover-right'>
                <a href="#"> <FaArrowLeft /> Back</a>
                <a href="#" className='home-icon'> <FaHome /></a>
              </div>
              <img src={imgUrl.imgUrl + `/storage/projects/${Projects.project_image_file}`} className='img-fluid' alt={Projects.project_name} />

              <div className='booking-form-div'>
                <Form>
                  <div className='booking-box-content'>
                    {/* <div className='img-div'>
                      <img src={imgUrl.imgUrl + `/storage/plots/${plotDetail.plot_image}`} className='img-fluid' alt={plotDetail.plot_number} />
                    </div> */}

                    <div className='content-div'>
                      <div className='box-title'>
                        <div className='box-logo'>
                          <img src={imgUrl.imgUrl + `/storage/projects/${Projects.logo_file}`} className='img-fluid' alt={Projects.project_name} />
                        </div>
                        <h2>Block <span className='orange-text'>{plotDetail.blocks.block_name}</span></h2>
                        {/* <h2>Section <span className='orange-text'>{plotDetail.section}</span></h2> */}
                        <h2>Plot No <span className='orange-text'>{plotDetail.plot_number}</span></h2>
                      </div>
                      <div className='booking-form'>
                        <div className='form'>
                          <a className='booking-box'>
                            <small>Price</small>
                            <p><del>₹ {plotDetail.actual_price}</del></p>
                          </a>
                          <a className='booking-box'>
                            <small>Offer Price</small>
                            <p>₹ {plotDetail.offer_price}</p>
                          </a>
                          <a className='booking-box'>
                            <small>Area</small>
                            <p>{plotDetail.plot_size} sqft</p>
                          </a>
                          <a className='booking-box'>
                            <small>Direction</small>
                            <p>{plotDetail.direction}</p>
                          </a>

                          <div>
                            {/* <input type="hidden" name="name" value={userData.name} /> */}
                            {/* <input type="hidden" name="phone" value={userData.phone} /> */}
                            <input type="hidden" name="plot_id" value={plotDetail.id} />
                            <input type="hidden" name="plot_number" value={plotDetail.plot_number} />
                            <input type="hidden" name="plot_type" value={plotDetail.plot_type} />
                            {/* <input type="hidden" name="section" value={plotDetail.section} /> */}
                            <input type="hidden" name="block" value={plotDetail.blocks.block_name} />
                            <input type="hidden" name="direction" value={userData.direction} />
                            <input type="hidden" name="area" value={plotDetail.plot_size} />
                            <input type="hidden" name="offer_price" value={userData.price} />
                            <input type="hidden" name="project_id" value={projectId} />
                            {/* <input type="hidden" name="user_id" value={userData.user_id} /> */}
                          </div>

                          <div className='row wrap'>
                            <Button className='booking-btn' onClick={EmiCalculatorhandleShow}>
                              <span className="mat-button-wrapper">
                                <AccountBalanceWalletIcon fontSize="large" />
                                <span _ngcontent-vxp-c103="">EMI Calculator</span>
                              </span>
                            </Button>
                            <Button className='booking-btn-success' onClick={handleBookNow}>
                              <span className="mat-button-wrapper">
                                <span _ngcontent-vxp-c103="">Book Now</span>
                              </span>
                            </Button>
                            <Button className='booking-btn-success' onClick={handleWishList}>
                              <span className="mat-button-wrapper">
                                <span _ngcontent-vxp-c103="">Add to Wishlist</span>
                              </span>
                            </Button>                            
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </Form>
              </div>
            </div>
          </div>
          <div className='block-div block-large-div'>
            <div className='user-name'>
              <p>{user.name} </p>
            </div>
            {/* <div className='user-icon'>
              <FaRegUserCircle />
            </div> */}
             <div className='user-icon'
              onClick={handleShowUserDetailModal}
            >
              <FaRegUserCircle />
            </div>
            <UserDetailModal
              show={showUserDetailModal}
              onClose={handleCloseUserDetailModal}
            />
          </div>
        </div>

        
     )} 

    {isSignedIn && ( 
        <div>
          <Modal className="filter-popup emi-calculator" show={EmiCalculatorShow} size='lg' onHide={EmiCalculatorhandleClose} aria-labelledby="contained-modal-title-vcenter"
            centered>          
            <Modal.Body>
              <div className='close-button ' onClick={EmiCalculatorhandleClose}>
                <div className='close-icon'></div>
              </div>
              <EmiCalculatorModal />
            </Modal.Body>
          </Modal>
        </div>
    )} 

    </main>
  );
}

export default PlotBookLayout;
